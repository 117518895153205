<template>
  <v-container fluid>
    <v-card-title>
      편지 수정
    </v-card-title>
    <DailyLetter />
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DailyLetter from '@/components/DailyLetter.vue'

export default defineComponent({
  components: { DailyLetter },
})
</script>
